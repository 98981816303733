import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import { Box, Heading } from '@primer/react';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const DoDontContainer = makeShortcode("DoDontContainer");
const Do = makeShortcode("Do");
const Caption = makeShortcode("Caption");
const Dont = makeShortcode("Dont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>
  <img src="https://user-images.githubusercontent.com/293280/193331583-020b59af-d749-402a-b8fd-095aaada098c.png" alt="" width="960" />
    </p>
    <h2>{`Overview`}</h2>
    <p>{`Humans process visual information by breaking down shapes and colors. The visual processing system parses colors and textures in parallel, while parsing shapes and forms one at a time.`}</p>
    <p>{`Layouts are composed of shapes, and therefore parsed linearly. As a consequence, a layout should be organized aiming to create the least amount of friction for users.`}</p>
    <p>{`People rely on their natural reading order (top-to-bottom, left-to-right in Latin script), and their acquired mental models from previous interactions to discover what they are looking for.`}</p>
    <p>{`At a quick glance, a layout should provide a clear understanding of the content hierarchy and its relationship. Having a consistent layout throughout the platform allows people to focus on their tasks rather than on learning new models. It helps them to navigate around with ease and confidence.`}</p>
    <p>{`Constructing a layout that is visually and semantically clear improves how people digest the content, and is aligned with an accessible implementation. Assistive technologies, such as screen readers and keyboard navigation, rely on semantic markup to understand the page structure and translate it into an accessible interaction.`}</p>
    <p>{`Designing with these considerations in mind results in a user experience that is consistent, inclusive, and responsive.`}</p>
    <h3>{`Takeways`}</h3>
    <h4>{`Strive for a focused experience`}</h4>
    <p>{`A page should enable the person to focus on the content. We should respect the user’s attention, and provide an experience that is clean, calm, and uncluttered.`}</p>
    <h4>{`Design fully-functional responsive experiences`}</h4>
    <p>{`Pages should adapt to smaller screens, without loss of functionality. Multi-column page layouts need to be designed for scenarios where not all columns fit the viewport at once. Break down layout into multiple views for mobile-friendly experiences.`}</p>
    <h4>{`Consider consistency inside and outside GitHub`}</h4>
    <p>{`Leverage existing mental models people have from using GitHub, the web, and other computer software. Use familiar patterns and conventions to create an experience that can be rationally understood.`}</p>
    {/* Comment about permalinks? */}
    <h2>{`Responsive foundations`}</h2>
    <p>{`Primer provides two levels of abstraction for handling responsive designs:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Viewport ranges`}</strong>{`, for defining the layout and navigation affordance of a page at a high level.`}</li>
      <li parentName="ul"><strong parentName="li">{`Breakpoints`}</strong>{`, for fine-tuning custom experiences.`}</li>
    </ul>
    <h3>{`Viewport ranges`}</h3>
    <p>{`Viewport ranges target common scenarios when designing responsive layouts. They are based on the viewport width, and are opinionated about the number of layout columns that can fit in a given range.`}</p>
    <p>{`Viewport ranges enable designs to break down complex multi-column experiences into simpler layouts according to the the available space.`}</p>
    <p>{`Because they provide a clear separation between mobile and desktop patterns, viewport ranges allow pages to remain powerful on desktop-friendly scenarios, while allowing a targetted streamlined experience in small devices.`}</p>
    <p>
  <img width="960" alt="Viewport ranges" src="https://user-images.githubusercontent.com/293280/193167177-87175665-4f52-4d3a-aac3-d09f408c05c4.png" />
    </p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Viewport range`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Width range`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Columns`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`narrow`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`< 768px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Supports a single-column layout. Also known as “mobile”.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`regular`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`>= 768px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Up to 2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All desktop-friendly patterns start at this range.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`wide`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`>= 1400px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Up to 3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional range when a 3rd layout column is needed.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Breakpoints`}</h3>
    <p>{`Breakpoints enable designers to fine-tune their responsive experiences, adjusting any specific responsive scenarios that are not addressed by viewport ranges.`}</p>
    <p>{`Historically, Primer has utilized breakpoints as the base of `}<a parentName="p" {...{
        "href": "https://github.com/primer/css/blob/b5e009778ec01b6e983cba6cbf89cebfdc5a4124/docs/content/support/breakpoints.md"
      }}>{`mobile-first responsive CSS utilities`}</a>{`. While these utilities values remain available, breakpoints are no longer tied to a `}<inlineCode parentName="p">{`min-width`}</inlineCode>{` mobile-first media query approach.`}</p>
    <p>{`Breakpoint sizes should be simply seen as a unit in a ruler. The numbers are not opinionated into how they should be used when applied to a media query. That is, they don't refer to ranges that go upwards or downwards.`}</p>
    <p>{`While viewport ranges should be used for distinguishing responsive layout adaptations, custom media queries built with these breakpoints values can be used internally for adjusting any specific responsive scenarios that require a fine-tune level of control.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Breakpoint`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Size`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`xsmall`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`320px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`small`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`544px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`medium`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`768px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`large`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1012px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`xlarge`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1280px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`xxlarge`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1400px`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`For more information about viewport ranges and breakpoints, check the `}<a parentName="p" {...{
        "href": "https://github.com/github/primer/blob/main/adrs/2022-04-15-responsive-design-api-guidelines.md"
      }}>{`Responsive API design guidelines ADR`}</a>{` (currently only available for hubbers).`}</p>
    <h3>{`Padding`}</h3>
    <p>{`To provide more space for content on smaller screens, the padding should be adjusted according to the different breakpoints.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Breakpoint`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Content`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Pane`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`xsmall`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`small`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`medium`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`large`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`xlarge`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`24px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`xxlarge`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`24px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16px`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`The padding is applied directly to the content or pane area, not to its parent container.`}</p>
    <p>{`For example, the content area on an `}<inlineCode parentName="p">{`xlarge`}</inlineCode>{` breakpoint will have a max-width of 1280px. The 1280px includes the 24px padding, which means that visually, the max-width of the content area on an `}<inlineCode parentName="p">{`xlarge`}</inlineCode>{` breakpoint will only be 1232px.`}</p>
    <p><img parentName="p" {...{
        "src": "https://github.com/user-attachments/assets/4e47b827-3263-4b4b-a59a-d71d3df1fcd4",
        "alt": "The GitHub settings page when viewed on a `xlarge` breakpoint with it's padding annotated."
      }}></img></p>
    <h2>{`Anatomy of a page`}</h2>
    <p>
  <img src="https://user-images.githubusercontent.com/293280/192653282-e728781c-6024-4d11-9388-12ad91ae388a.png" alt="Anatomy of a page" />
    </p>
    <h3>{`App header`}</h3>
    <p>{`App header is GitHub’s topmost bar. This header contains global navigation and actions, but also contextual navigation elements such as `}<strong parentName="p">{`Context region`}</strong>{` and `}<strong parentName="p">{`Local navigation`}</strong>{`.`}</p>
    <p>{`It may also contain other system-level elements, such as notification banners.`}</p>
    <p>{`App header is never fixed to the top of the viewport. It scrolls with the rest of the page.`}</p>
    <h3>{`Context region`}</h3>
    <p>{`Context region informs the current section where the person is located. For example, in any page that belongs to a repository, it shows `}<inlineCode parentName="p">{`:owner / :repository`}</inlineCode>{`.`}</p>
    <p>{`Context region is not a full-path breadcrumb. Instead, it works alongside elements such as Local navigation and other in-page navigation components to give the user a full mental model of where they are located.`}</p>
    <p>{`Don’t include custom interactive elements such as dropdown selectors or other buttons in the Context region.`}</p>
    <h4>{`Repository context`}</h4>
    <DoDontContainer mdxType="DoDontContainer">
      <Do indented mdxType="Do">
        <p><inlineCode parentName="p">{`:owner / :repository`}</inlineCode></p>
        <Caption mdxType="Caption">Do represent repositories as direct sub-items of an organization or user in the Context region.</Caption> 
      </Do>
      <Dont indented mdxType="Dont">
        <p><inlineCode parentName="p">{`:owner / :repository / Issues`}</inlineCode></p>
        <Caption mdxType="Caption">Don’t use a Context region to show the full page’s path. A selected “Issues” item in the local navigation should provide enough complementary context.</Caption>
      </Dont>
    </DoDontContainer>
    <h4>{`Owner context (organization or users)`}</h4>
    <DoDontContainer mdxType="DoDontContainer">
      <Do indented mdxType="Do">
        <p><inlineCode parentName="p">{`:owner`}</inlineCode></p>
        <Caption mdxType="Caption">Do represent top-level sections with a single Context region item.</Caption>
      </Do>
      <Dont indented mdxType="Dont">
        <p><inlineCode parentName="p">{`:owner / Projects`}</inlineCode></p>
        <Caption mdxType="Caption">Don’t use a Context region to show the full page’s path. A selected “Projects” item in the local navigation should provide enough complementary context.</Caption>
      </Dont>
    </DoDontContainer>
    <h4>{`Other owned categories`}</h4>
    <DoDontContainer mdxType="DoDontContainer">
      <Do indented mdxType="Do">
        <p><inlineCode parentName="p">{`:owner / Projects / :project`}</inlineCode></p>
        <Caption mdxType="Caption">Do represent objects other than repositories as sub-items of a category.</Caption> 
      </Do>
      <Dont indented mdxType="Dont">
        <p><inlineCode parentName="p">{`:owner / Projects / :project / Insights`}</inlineCode></p>
        <Caption mdxType="Caption">Don’t display the full page’s path. The page should use local or in-page navigation elements to provide such context.</Caption>
      </Dont>
    </DoDontContainer>
    <h3>{`App footer`}</h3>
    <p>{`App footer contains useful links and legal information about GitHub. It remains “after the fold” in smaller pages to keep the focus on the main content.`}</p>
    <p>{`The App footer should be present in all core pages. Experiences that require rich interactions, such as when manipulating large amounts of data, may opt-out of the App footer.`}</p>
    <h2>{`Page types`}</h2>
    <p>{`Page layouts can have many different forms and needs. The list below contain common page types that can be used as a starting point for designing new experiences.`}</p>
    <Box display="grid" gridTemplateColumns={['1fr', null, null, null, '1fr 1fr']} gridGap={5} mdxType="Box">
  <Box justifyContent="center" mdxType="Box">
      <img width="464" alt="" src="https://user-images.githubusercontent.com/293280/193167353-d945da51-665f-4d9b-81f6-904744901d51.png" />
  </Box>
  <Box mdxType="Box">
      <Heading as="h3" sx={{
          fontSize: 3,
          mb: 2
        }} mdxType="Heading">Full pages</Heading>
        <p>{`This is the classic type of a page design of GitHub, where both content and pane regions appear horizontally centered to the viewport.`}</p>
        <p>{`Page layouts generally limit their maximum width to `}<inlineCode parentName="p">{`xlarge`}</inlineCode>{` (1280px) so the content region doesn’t render paragraphs with too many words per line.`}</p>
  </Box>
  <Box justifyContent="center" mdxType="Box">
      <img width="464" alt="" src="https://user-images.githubusercontent.com/293280/193167352-51d74a57-4e62-488a-92f9-581d0c4ca15a.png" />
  </Box>
  <Box mdxType="Box">
      <Heading as="h3" sx={{
          fontSize: 3,
          mb: 2
        }} mdxType="Heading">Split pages</Heading>
        <p>{`Split page layouts are a good options for pages that have side navigation, filtering, or any type of list-detail pattern.`}</p>
        <p>{`Split pages separate the viewport in two, allowing the pane to have an independent scrollable area. This is useful when the pane has a long list of items, and the user needs to scroll through the list without losing the context of the selected item.`}</p>
        <p>{`The pane region of a split page layout is always flushed to the left. Don’t use right-aligned flushed panes as their scrollbar may conflict with the page scrollbar.`}</p>
        <p>{`The content region of a split page layout may have a maximum width. In those scenarios, the content region tries to remain centered horizontally to the viewport if there's space.`}</p>
  </Box>
  <Box justifyContent="center" mdxType="Box">
      <img width="464" alt="" src="https://user-images.githubusercontent.com/293280/193167350-b4bd5626-af85-464c-9b0c-df6fbe26a6f0.png" />
  </Box>
  <Box mdxType="Box">
      <Heading as="h3" sx={{
          fontSize: 3,
          mb: 2
        }} mdxType="Heading">Interstitial pages</Heading>
        <p>{`Used for signing-in experiences, password verification, loading states, or other long operations.`}</p>
        <p>{`Interstitial pages usually have an `}<inlineCode parentName="p">{`xsmall`}</inlineCode>{` (320px) maximum width.`}</p>
  </Box>
    </Box>
    <h2>{`Layout regions`}</h2>
    <p>{`Layout regions are the main structural building blocks of a page layout. They are the areas where content and components are placed, and group elements that share a common purpose.`}</p>
    <p>{`Layout regions make it easy to adapt the page to different viewport ranges. For more information about responsive behavior, see `}<a parentName="p" {...{
        "href": "#responsive-behavior"
      }}>{`Responsive behavior`}</a>{` below.`}</p>
    <h3>{`Header region`}</h3>
    <p>{`Headers appear at the top of the page, and include a page title, alongside optional actions, summary, local navigation, and metadata.`}</p>
    <h3>{`Content region`}</h3>
    <p>{`The content region is used for displaying the main subject of the page. Other regions support the content with additional information, either about, or related to it.`}</p>
    <h3>{`Left pane region`}</h3>
    <p>{`Display navigation, filtering, or an overview for entities such as users, bots, apps, etc.`}</p>
    <h3>{`Right pane region`}</h3>
    <p>{`Display item metadata, details, and other auxiliary information.`}</p>
    <h3>{`Footer region`}</h3>
    <p>{`Use it to display less important information, such as references, or links to other pages.`}</p>
    <h2>{`Responsive behavior`}</h2>
    <p>{`Narrow viewports support a single column when displaying page layouts. The list below contain common responsive adaptations to make sure experiences can remain usable on smaller screens.`}</p>
    <Box display="grid" gridTemplateColumns={['1fr', null, null, null, '1fr 1fr']} gridGap={5} mdxType="Box">
  <Box justifyContent="center" mdxType="Box">
      <img width="464" alt="" src="https://user-images.githubusercontent.com/293280/193167623-16947bb7-aadd-4532-91d4-67f2c10c1e37.png" />
  </Box>
  <Box mdxType="Box">
      <Heading as="h3" sx={{
          fontSize: 3,
          mb: 2
        }} mdxType="Heading">Split into different pages</Heading>
        <p>{`List-detail patterns, such as settings pages, can be split into different pages when the viewport is narrow.`}</p>
        <p>{`In this behavior, only one of pane or content regions is shown. The user can switch between the two by selecting an item from the list to drill-in into its details, or navigate back using a parent link in the page header.`}</p>
        <p>{`See the `}<a parentName="p" {...{
            "href": "/ui-patterns/navigation#responsive-sidebar-navigation-patterns"
          }}>{`responsive sidebar navigation patterns`}</a>{` section of our navigation guidelines for more information.`}</p>
  </Box>
  <Box justifyContent="center" mdxType="Box">
      <img width="464" alt="" src="https://user-images.githubusercontent.com/293280/193167620-71c4d803-b9d6-4ff0-8f42-9be100b672ac.png" />
  </Box>
  <Box mdxType="Box">
      <Heading as="h3" sx={{
          fontSize: 3,
          mb: 2
        }} mdxType="Heading">Show pane as bottom sheet</Heading>
        <p>{`Panes can be displayed as bottom sheets when they’re used to display auxiliary information, such as metadata, details or actions. Use narrow viewport-specific button triggers to open the pane as a bottom sheet.`}</p>
        <p>{`If you're using a pane as a sidebar for navigating or filtering, see the `}<a parentName="p" {...{
            "href": "/ui-patterns/navigation#responsive-sidebar-navigation-patterns"
          }}>{`responsive sidebar navigation patterns`}</a>{` section of our navigation guidelines for more information.`}</p>
  </Box>
  <Box justifyContent="center" mdxType="Box">
      <img width="464" alt="" src="https://user-images.githubusercontent.com/293280/193167624-f66af4a2-49b9-4610-bf76-36f77a5521e8.png" />
  </Box>
  <Box mdxType="Box">
      <Heading as="h3" sx={{
          fontSize: 3,
          mb: 2
        }} mdxType="Heading">Stack vertically</Heading>
        <p>{`In scenarios where the pane is used to display an overview of the content, it can be stacked vertically on top of the content region. Metadata and auxiliary information can appear stacked below the content region.`}</p>
        <p>{`Avoid stacking a pane on top of the main content area if the pane has a lot of links. It will push the main content down and force the user to scroll to get to the content. For alternative patterns, see the `}<a parentName="p" {...{
            "href": "/ui-patterns/navigation#responsive-sidebar-navigation-patterns"
          }}>{`responsive sidebar navigation patterns`}</a>{` section of our navigation guidelines.`}</p>
        <p>{`Additionally, a small pane summary may appear above the content, with a trigger used to display the full details as a bottom sheet.`}</p>
  </Box>
    </Box>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      